import React from "react";
import { Link, Button, Box, TextField, Typography } from "@mui/material";
//Assets
// Local
import {
  Form,
  Navigation,
  useFormik,
  useLocation,
  yup,
  RouterLink,
} from "../../lib";
import { authActions, useAction } from "../../state";
import {
  ContainerStyle,
  FormStyle,
  TextFlexStyle,
  CenterElementStyle,
} from "./Auth.styles";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const ForgotPasswordPage = React.memo(
  /**
   *
   */
  function ForgotPasswordPage() {
    const recoverPassword = useAction(authActions.recoverPassword);
    const location = useLocation();

    const frm = useFormik({
      initialValues: {
        email: location.query.email || "",
      },
      validationSchema,
      async onSubmit(values) {
        recoverPassword(values);
        Navigation.go(`/auth/recovery-sent?email=${values.email}`);
      },
    });

    return (
      <ContainerStyle>
        <Box>
          <Typography variant="h3" gutterBottom>
            Reset Password
          </Typography>

          <Typography variant="body1" gutterBottom>
            Enter your email we'll send you a link to reset your password. If
            you don't have an email reach out to the office.
          </Typography>

          <FormStyle>
            <Form form={frm}>
              <TextFlexStyle>
                <TextField
                  autoFocus
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={frm.values.email}
                  onChange={frm.handleChange}
                  error={frm.touched.email && Boolean(frm.errors.email)}
                  helperText={frm.touched.email && frm.errors.email}
                />
              </TextFlexStyle>
              <Button
                variant="contained"
                type="submit"
                disabled={frm.isSubmitting}
                fullWidth
                sx={{
                  marginBottom: "1rem",
                }}
              >
                Email me a reset link
              </Button>
              <CenterElementStyle>
                <Link component={RouterLink} to={`/auth/login`}>
                  <Typography variant="body2"> Back to login </Typography>
                </Link>
              </CenterElementStyle>
            </Form>
          </FormStyle>
        </Box>
      </ContainerStyle>
    );
  },
);
