import { AppState } from "../types";
import { UIViewState } from "./state";

export const uiSelectors = {
  loading(state: AppState) {
    return state.ui.loading;
  },

  notification(state: AppState) {
    return state.ui.notification;
  },

  menuOpen(state: AppState) {
    return state.ui.menuOpen;
  },
  // Views

  /** Dynamically creates a selector to select the given `view[name]`. */
  forView(name: keyof UIViewState) {
    return (state: AppState) => state.ui.view[name];
  },
};
