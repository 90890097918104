import React from "react";
import { Button } from "@mui/material";
import { RootStyle } from "./HomePage.styles";
import LandingHero from "../../components/_home/LandingHero";
import { Link } from "react-router-dom";

function _HomePage() {
  return (
    <RootStyle
      title="The starting point for your next project | Minimal-UI"
      id="move_top"
    >
      <Button
        sx={{
          color: "#000",
          backgroundColor: "#E8E8E8",
          position: "absolute",
          width: 150,
          top: 60,
          right: 60,
          zIndex: 99,
        }}
      >
        <Link to="/auth/login">Login</Link>
      </Button>
      <LandingHero />
    </RootStyle>
  );
}

export const HomePage = React.memo(_HomePage);
