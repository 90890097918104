import React from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import { useLocation, RouterLink } from "../../lib";
import { ContainerStyle, CenterElementStyle } from "./Auth.styles";
import { authActions, useAction } from "../../state";

export const RecoverySentPage = React.memo(
  /**
   *
   */
  function RecoverySentPage() {
    const location = useLocation();
    const recoverPassword = useAction(authActions.recoverPassword);
    const resendEmail = (_e) => {
      recoverPassword({ email: location.query.email });
    };
    return (
      <ContainerStyle>
        <Box>
          <Typography variant="h3" gutterBottom>
            Email sent
          </Typography>
          <Typography variant="body1" gutterBottom>
            If {location.query.email || "email provided"} is a valid user's
            email, an email has been sent to reset your password.
          </Typography>
          <div>
            <Button
              variant="contained"
              type="button"
              fullWidth
              onClick={resendEmail}
              sx={{
                mt: 5,
                mb: 2,
              }}
            >
              Resend email
            </Button>
          </div>
          <Typography variant="body2">
            If it doesn't arrive soon check your spam folder.r{" "}
          </Typography>
          <CenterElementStyle>
            <Link component={RouterLink} to={`/auth/login`}>
              <Typography variant="body2"> Back to login </Typography>
            </Link>
          </CenterElementStyle>
        </Box>
      </ContainerStyle>
    );
  },
);
