import React from "react";
import { createBrowserHistory } from "history";
import {
  Link as RouterLink,
  // Not employing `useHistory` since it causes unnecessary re-renders.
  // - https://github.com/ReactTraining/react-router/issues/6999
  useLocation as useRouterLocation,
  // useRouteMatch,
  useParams as useRouteParams,
  generatePath,
} from "react-router-dom";
// import ScrollBehavior from "scroll-behavior";
// Local
// import ScrollStateStorage from "./ScrollStateStorage";

export {
  // Re-export routing library modules for convenience.
  generatePath,
  RouterLink,
  useRouterLocation,
  // useRouteMatch,
  useRouteParams,
};

/**
 * The react-router/history object.
 */
export const history = createBrowserHistory({});

/**
 * Scroller integrated with react-router.
 */
// // TODO: Figure out if scroll-behavior is still needed. See:
// // - https://reactrouter.com/web/guides/scroll-restoration
// export const scrollBehavior = new ScrollBehavior({
//   addNavigationListener: history.listen,
//   stateStorage: new ScrollStateStorage(),
//   getCurrentLocation() {
//     return history.location;
//   },
// });

/**
 * Navigation service to expand on `history` functions. Exposes `history`,
 * `queryString` and other routing library modules for convenience.
 */
export const Navigation = {
  history,
  /**
   * Uses HTML5 history to navigate to a url, path or page.
   * @param {string | PageDefinition} urlPathOrPage
   * @param {NavigationConfig} [config]
   */
  go(urlPathOrPage, config = undefined) {
    history.push(generateUrl(urlPathOrPage, config));
  },
  /**
   * Uses `window.location.assign` to navigate to a url, path or page.
   * **Warning: This unloads the current browser state.**
   * @param {string | PageDefinition} urlPathOrPage
   * @param {NavigationConfig} [config]
   */
  load(urlPathOrPage, config) {
    window.location.assign(generateUrl(urlPathOrPage, config));
  },
  /**
   * Uses `window.location` to `reload` the current address or `replace` the
   * current address with the given url, path or page.
   * **Warning: This unloads the current browser state.**
   * @param {string | PageDefinition} [urlPathOrPage]
   * @param {NavigationConfig} [config]
   */
  reload(urlPathOrPage, config = undefined) {
    if (!urlPathOrPage) {
      window.location.reload();
    } else {
      window.location.replace(generateUrl(urlPathOrPage, config));
    }
  },
  /**
   * Uses HTML5 history to navigate to a url, path or page, replacing the
   * current history item.
   * @param {string | PageDefinition} urlPathOrPage
   * @param {NavigationConfig} [config]
   */
  replace(urlPathOrPage, config = undefined) {
    history.replace(generateUrl(urlPathOrPage, config));
  },

  /**
   * Creates a url from the given url, path or page. If `config` is supplied,
   * the path `params` and `query` objects are merged into the url path.
   * @param {string | PageDefinition} urlPathOrPage
   * @param {NavigationConfig} [config]
   */
  url: generateUrl,

  fullUrl(urlPathOrPage, config) {
    const {
      location: { hostname, port, protocol },
    } = window;
    return (
      `${protocol}//${hostname}${port ? ":" + port : ""}` +
      generateUrl(urlPathOrPage, config)
    );
  },

  // Event Handlers

  /**
   * Event handler that calls `Navigation.history.goBack()`.
   * @param {React.SyntheticEvent<HTMLElement>} e
   */
  goBackHandler(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    history.back();
  },
};
if (process.env.NODE_ENV === "development") {
  // Let the developer use devtools to navigate easily.
  window._Navigation = Navigation;
}
/**
 * Creates a url from the given url, path or page. If `config` is supplied,
 * the path `params` and `query` objects are merged into the url path.
 * @param {string | PageDefinition} urlPathOrPage
 * @param {NavigationConfig} [config]
 */
export function generateUrl(urlPathOrPage, config = undefined) {
  let urlPath =
    typeof urlPathOrPage === "string" ? urlPathOrPage : urlPathOrPage.path;
  if (!config) {
    return urlPath;
  }
  const { params, query, hash = "" } = config;
  if (params) {
    urlPath = generatePath(urlPath, params);
  }
  const hashString =
    typeof hash === "string" ? (hash.startsWith("#") ? hash : `#${hash}`) : "";
  if (!query) {
    return `${urlPath}${hashString}`;
  } else {
    const queryString = generateQueryString(query);
    if (!queryString) {
      return `${urlPath}${hashString}`;
    }
    return `${urlPath}?${queryString}${hashString}`;
  }
}
/**
 * Creates a url query string from the given `query`. The generated query string
 * **DOES NOT** include a question mark.
 * @param {string[][] | Record<string, string> | string | URLSearchParams} query
 */
export function generateQueryString(query) {
  const searchParams = new URLSearchParams(query);
  return searchParams.toString();
}
/**
 * Parses the given query string into a plain object.
 * @param {string} value
 */
export function parseQueryString(value) {
  if (!value) {
    return {};
  }
  // CONSIDER: Handle duplicate query string parameters here if necessary.
  return Object.fromEntries(new URLSearchParams(value).entries());
}

export function getQueryParam(key) {
  if (!key) {
    return "";
  }
  return parseQueryString(window.location.search)[key];
}
/** Extended location hook that provides extra `params` and `query` props. */
export function useLocation() {
  const location = useRouterLocation();
  const params = useRouteParams();
  return React.useMemo(
    () => {
      return {
        ...location,
        /** Route path params. */
        params,
        /** Query params parsed from `location.search`. */
        query: parseQueryString(location.search),
      };
    },
    // If the location.pathname changes, params have changed. Since params is
    // constantly changing, don't depend on `params`...
    // eslint-disable-next-line
    [location],
  );
}
